var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    {
      staticClass: "helpCard",
      class: { highlight: _vm.isHighlight, lowlight: _vm.isLowlight }
    },
    [
      _c("CCardHeader", {
        domProps: { innerHTML: _vm._s(_vm.$t(_vm.name + ".title")) }
      }),
      _c(
        "CCardBody",
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.$t(_vm.name + ".text", _vm.textVariables))
            }
          }),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }